.App {
  text-align: center;
  background-color: #933335;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: "Staatliches", cursive;
}

@media only screen and (max-width: 600px) {
  .title {
    font-size: 5rem !important;
  }
  .secondTitle {
    font-size: 1.2rem !important;
  }
  .menu {
    font-size: 3rem !important;
  }

  .home {
    font-size: 2rem;
  }

  .date {
    font-size: 2rem !important;
  }
}

.cards {
  align-items: center;
  justify-content: center;
}

.font1 {
  color: #933335;
}

.shadow1 {
  box-shadow: 0 0 10px rgb(0, 0, 0);
}

.header {
  padding-top: 3rem;
}

.date {
  color: #fb5856;
  font-size: 3rem;
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.title {
  font-size: 7rem;
  margin-bottom: 0;
  text-shadow: 5px 5px black;
}

.secondTitle {
  font-family: "Raleway", sans-serif;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 8rem;
}

.footer {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  color: rgb(190, 188, 188);
  margin-top: 10rem;
  margin-bottom: 0;
}

.menu {
  font-size: 5rem;
}

.link {
  color: #ff7d26 !important;
}

.link:hover {
  color: #ff5b0f !important;
}

.python {
  margin-bottom: 5rem;
  margin-top: 0;
}

.menubar {
  margin-top: 6.5rem !important;
}
